<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<template>
  <el-popover
    :value="isOpen"
    width="330"
    trigger="manual"
    :popper-options="{
      removeOnDestroy: true,
    }"
    @show="popoverShowed"
    @hide="popoverHidden"
  >
    <div ref="popover" class="popover-container" @blur="onPopoverBlur">
      <div class="popover-title">
        <p class="title-text">{{ $t('terms.supplierTerms.supplierTermTable.simulation.popover.title') }}</p>
        <el-switch v-model="isSimulationActive" active-color="#00B37A" :width="30" @change="onToggleSimulation" />
      </div>
      <div class="divider" />
      <div class="popover-content">
        <div v-for="(priceMode, index) in mappedPriceModes" v-if="!priceMode.notPopover" :key="index">
          <el-radio
            v-model="chosenMode"
            :label="priceMode.name"
            :disabled="!isSimulationActive || priceMode.price === 0"
            class="ms-5 price-mode-selection"
          >
            <div
              v-if="
                priceMode.name !== PRICE_MODE_NAMES.CUSTOM && priceMode.name !== PRICE_MODE_NAMES.PREV_PERIOD_AVG_PRICE
              "
              class="price-mode-option"
            >
              <div class="icon-label">
                <SimulationArrowIcon :class="priceMode.arrowDir" :fill="isSimulationActive ? '#1F284D' : '#C4C6CF'" />
                <span class="label" :class="{ ['inactive']: !isSimulationActive }">{{ $t(priceMode.label) }}</span>
              </div>
              <div class="price" :class="{ ['inactive']: !isSimulationActive }">
                {{ formatMoney(priceMode.price) }}
              </div>
            </div>
            <div v-else-if="priceMode.name === PRICE_MODE_NAMES.PREV_PERIOD_AVG_PRICE" class="price-mode-option">
              <div class="icon-label">
                <span class="previous-icon"
                  ><SimulationPreviousAverageIcon :size="16" :fill="isSimulationActive ? '#1F284D' : '#C4C6CF'"
                /></span>
                <span class="label" :class="{ ['inactive']: !isSimulationActive }">{{ $t(priceMode.label) }}</span>
              </div>
              <div class="price" :class="{ ['inactive']: !isSimulationActive }">
                {{ formatMoney(priceMode.price) }}
              </div>
            </div>
            <div v-else-if="priceMode.name === PRICE_MODE_NAMES.CUSTOM" class="price-mode-option">
              <div class="icon-label">
                <SimulationPriceIcon :size="16" :fill="isSimulationActive ? '#1F284D' : '#C4C6CF'" />
                <span class="label" :class="{ ['inactive']: !isSimulationActive }">{{ $t(priceMode.label) }}</span>
              </div>
              <div class="price-input">
                <InputMoney
                  :disabled="!isSimulationActive || chosenMode !== PRICE_MODE_NAMES.CUSTOM"
                  :value="inputValue"
                  :formatter="formatMoney"
                  @input="onInputChange"
                />
              </div>
            </div>
          </el-radio>
        </div>
        <div class="footer">
          <div class="apply-button">
            <Button type="secondary" @click="togglePopover">{{ $t('cancel') }}</Button>
          </div>
          <div class="apply-button">
            <Button @click="onApply">{{ $t('terms.supplierTerms.supplierTermTable.simulation.popover.save') }}</Button>
          </div>
        </div>
      </div>
    </div>
    <slot slot="reference"></slot>
  </el-popover>
</template>
<script>
import { computed, ref } from 'vue';

import { useCurrency } from '@/locale/useCurrency';
import { SimulationArrowIcon, SimulationPriceIcon, SimulationPreviousAverageIcon } from '@/assets/icons';
import { InputMoney, Button } from '@/modules/core';

import { formatCurrencyOrDash } from '../../purchaseManagementFormatters';
import { PRICE_MODE_NAMES, priceModes } from './supplierProductsTableConstants';

export default {
  components: {
    SimulationArrowIcon,
    SimulationPriceIcon,
    SimulationPreviousAverageIcon,
    InputMoney,
    Button,
  },
  props: {
    minDatePrice: { type: Number, default: 0 },
    simulationMode: { type: String, default: PRICE_MODE_NAMES.FIRST_PRICE },
    maxDatePrice: { type: Number, default: 0 },
    maxPrice: { type: Number, default: 0 },
    minPrice: { type: Number, default: 0 },
    previousAveragePrice: { type: Number, default: 0 },
    isOpen: { type: Boolean, required: true },
    isSimActive: { type: Boolean, required: true },
  },
  emits: ['on-apply', 'on-toggle-popover'],
  setup(props, { emit }) {
    const { currencyFormat } = useCurrency();

    const isSimulationActive = ref(props.isSimActive);
    const popover = ref(null);
    const chosenMode = ref(props.simulationMode);
    let initialState = {};

    const mappedPriceModes = computed(() =>
      priceModes.map((priceMode) => {
        let price;
        const name = priceMode.name;
        if (name === PRICE_MODE_NAMES.FIRST_PRICE) {
          price = props.minDatePrice;
        } else if (name === PRICE_MODE_NAMES.LAST_PRICE) {
          price = props.maxDatePrice;
        } else if (name === PRICE_MODE_NAMES.HIGHEST_PRICE) {
          price = props.maxPrice;
        } else if (name === PRICE_MODE_NAMES.LOWEST_PRICE) {
          price = props.minPrice;
        } else if (name === PRICE_MODE_NAMES.PREV_PERIOD_AVG_PRICE) {
          price = props.previousAveragePrice;
        }

        return { ...priceMode, price };
      })
    );

    const chosenModeObject = computed(() =>
      mappedPriceModes.value.find((priceMode) => priceMode.name === chosenMode.value)
    );
    const initialInputValue = Math.round(chosenModeObject.value.price * 100) / 100;
    const inputValue = ref(initialInputValue * 100);
    const value = computed(() =>
      chosenMode.value === PRICE_MODE_NAMES.CUSTOM ? inputValue.value : chosenModeObject.value.price
    );

    const onInputChange = (newPrice) => {
      inputValue.value = newPrice;
    };

    const togglePopover = () => {
      emit('on-toggle-popover');
    };

    const onToggleSimulation = (updatedSimStatus) => {
      isSimulationActive.value = updatedSimStatus;
    };

    const onApply = () => {
      initialState = {};
      if (!isSimulationActive.value) {
        emit('on-apply', { newPrice: null, newMode: chosenMode.value });
      } else {
        const price = chosenMode.value === PRICE_MODE_NAMES.CUSTOM ? value.value / 100 : value.value;
        emit('on-apply', { newPrice: price, newMode: chosenMode.value });
      }
      togglePopover();
    };

    const onPopoverBlur = (event) => {
      if (!event.relatedTarget) {
        togglePopover();
      }
    };

    const popoverShowed = () => {
      isSimulationActive.value = props.isSimActive;
      chosenMode.value = props.simulationMode;
      initialState = {
        mode: chosenMode.value,
        isSimActive: isSimulationActive.value,
        value: value.value,
      };
    };

    const popoverHidden = () => {
      if (initialState.mode) {
        chosenMode.value = initialState.mode;
        isSimulationActive.value = initialState.isSimActive;
        initialState = {};
      }
    };

    return {
      isSimulationActive,
      value,
      mappedPriceModes,
      chosenMode,
      inputValue,
      popover,
      PRICE_MODE_NAMES,
      onInputChange,
      formatMoney: (number) => formatCurrencyOrDash(number, currencyFormat.value),
      togglePopover,
      onPopoverBlur,
      popoverShowed,
      popoverHidden,
      onApply,
      onToggleSimulation,
    };
  },
};
</script>
<style lang="scss" scoped>
::v-deep {
  .el-input__inner {
    height: 32px;
  }

  .el-radio:last-child {
    margin-right: 0;
    margin-left: 0 !important;
  }
}

.popover-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .inactive {
    color: #c4c6cf !important;
  }
  .divider {
    height: 1px;
    width: 100%;
    background: #d9dcde;
  }
  .popover-title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title-text {
      font-size: 14px;
      font-weight: 500;
    }
  }
  .popover-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .price-mode-selection {
      display: flex;
      align-items: center;
      .price-mode-option {
        display: flex;
        justify-content: space-between;
        width: 280px;
        color: black;
        align-items: center;
        margin-top: -1px;
      }

      .icon-label {
        display: flex;
        gap: 0.2rem;
        font-size: 14px;

        .label {
          margin-top: 1px;
        }
        .previous-icon {
          margin-top: -1px;
        }
      }
      .price {
        margin-top: 1px;
        color: #9295a5;
      }
    }
    .price-input {
      width: 100px;
      height: 32px;
    }
    .up {
      transform: rotate(0);
    }
    .down {
      transform: rotate(180deg);
    }
    .right {
      transform: rotate(90deg);
    }
    .left {
      transform: rotate(270deg);
    }

    .content-text {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
    }
    .apply-button {
      height: 32px;
    }
  }
  .footer {
    display: flex;
    width: 100%;
    gap: 0.5rem;
    justify-content: flex-end;
    margin-top: 4px;
  }
}
</style>
