<template>
  <div>
    <div class="table-header">
      <div>
        <h3>{{ $t('terms.supplierTerms.productsPurchased') }}</h3>
      </div>
      <div class="table-buttons">
        <ExcelExport @on-export-option-choice="exportExcel" />
      </div>
    </div>
    <div class="table-description">
      <SearchBar
        :search-text-ref="searchProductText"
        :input-placeholder="$t('terms.supplierTerms.supplierTermTable.settings.searchProduct')"
        @on-supplier-text-change="onProductTextChange"
      />
      <DisplayModeDateViews
        :preset-type="PRESET_TYPES.PRODUCTS"
        :columns="columns"
        :date-ranges-state="dateRangesState"
        :date-ranges-label="dateRangesLabel"
        :show-purchase-list-filter="showPurchaseListFilter"
        @on-purchase-list-filter-change="onPurchaseListFilterChange"
        @on-columns-update="onColumnsUpdate"
        @on-date-range-update="(updatedDateRange) => $emit('on-date-range-update', updatedDateRange)"
      />
    </div>
    <div v-if="anyPeriodPreviousColumns || anySimulationColumns" class="total">
      <div class="total-item">
        <p class="total-text">{{ $t('terms.supplierTerms.supplierTermTable.simulation.purchases') }}</p>
        <p>{{ formatCurrency(descriptionData.totalPurchases) }}</p>
      </div>
      <div v-if="anySimulationColumns" class="total-divider"></div>
      <div v-if="anySimulationColumns" class="total-item">
        <p class="total-text">{{ $t('terms.supplierTerms.supplierTermTable.simulation.purchaseBySimulation') }}</p>
        <p>{{ formatCurrency(descriptionData.totalPurchasesBySim) }}</p>
      </div>
      <div class="total-divider"></div>
      <div
        class="total-item"
        :set="
          (difference = anySimulationColumns ? descriptionData.totalDifferenceSim : descriptionData.totalDifference)
        "
      >
        <p class="total-text">{{ $t('terms.supplierTerms.supplierTermTable.simulation.difference') }}</p>
        <div class="total-with-icon">
          <p>{{ formatCurrency(difference) }}</p>
          <p v-if="difference == null || difference == 0"></p>
          <SimulationTableArrowUpIcon v-if="difference > 0" :size="20" fill="#E52044" />
          <SimulationTableArrowDownIcon v-else :size="20" fill="#00B37A" />
        </div>
      </div>
    </div>
    <div>
      <SupplierProductsMainTable
        :supplier-products-overview="supplierProductsOverview"
        :loading="loading"
        :columns="columns"
        :previous-period-dates="previousPeriodDates"
        :search-product-text="searchProductText"
        :amount-of-comparisons="amountOfComparisonsInData"
        :date-ranges="dateRangesLabel"
        :purchase-list-filter="purchaseListFilter"
        @on-column-update="onColumnsUpdate"
        @on-total-update="onTotalUpdate"
        @on-update-mutated-data="onUpdateMutatedData"
        @on-purchase-list-status-update="(args) => $emit('on-purchase-list-status-update', args)"
      />
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';

import { useCurrency } from '@/locale/useCurrency';

import { SimulationTableArrowUpIcon, SimulationTableArrowDownIcon } from '@/assets/icons';
import { PRESET_TYPES } from '@/modules/purchase-management/tools/constants';
import { ExcelExport } from '@/modules/core';

import { getDefaultPreset, getSupplierProductsColumns } from './supplierProductsTableColumns';
import { exportSupplierProducts } from './supplierProductsTableExcel';
import { COLUMN_CATEGORIES, SUPPLIER_PRODUCTS_TABLE_HEADERS } from './supplierProductsTableConstants';
import SupplierProductsMainTable from './SupplierProductsMainTable';
import SearchBar from '../SearchBar';
import DisplayModeDateViews from '../DisplayModeDateViews';

import { getDateRangeStringFromDates, formatCurrencyOrDash } from '../../purchaseManagementFormatters';
import { useUserPermissions } from '@/modules/auth';
import { USER_NEW_PERMISSIONS_LIST } from '@/permissions';

export default {
  components: {
    SimulationTableArrowUpIcon,
    SimulationTableArrowDownIcon,
    SupplierProductsMainTable,
    ExcelExport,
    SearchBar,
    DisplayModeDateViews,
  },
  props: {
    supplier: { type: Object, required: true },
    supplierProductsOverview: { type: Object, required: true },
    previousPeriodDates: { type: Object, required: true },
    loading: { type: Boolean, required: true },
    dateRangesState: { type: Array, required: true, default: () => [] },
  },
  emits: ['on-purchase-list-status-update', 'on-date-range-update'],
  setup(props) {
    const { currencyFormat } = useCurrency();

    const purchaseListFilter = ref(null);
    const searchProductText = ref('');
    const { isUserPermittedForActiveTenant } = useUserPermissions();
    const hasOrderProcessPermission = isUserPermittedForActiveTenant(USER_NEW_PERMISSIONS_LIST.ORDER_PROCESS);

    const activeSort = ref({
      direction: -1,
      columnKey: SUPPLIER_PRODUCTS_TABLE_HEADERS.TOTAL_PURCHASES,
    });
    const columns = ref(getDefaultPreset(getSupplierProductsColumns(activeSort)));

    const descriptionData = ref({});

    const tableDataReadyForExcel = ref(null);

    const onProductTextChange = (productText) => (searchProductText.value = productText);

    const dateRangesLabel = computed(() =>
      props.dateRangesState.map((dateRange) =>
        getDateRangeStringFromDates(dateRange.startDate, dateRange.endDate, false)
      )
    );
    const mutatedColumns = computed(() => {
      const originalColumns = getSupplierProductsColumns(activeSort);
      const comparisonOriginalColumns = originalColumns.filter((column) => column.isComparisonColumn);

      return columns.value
        .map((column) => {
          if (!column.isComparisonColumn) return column;

          const originalColumn = comparisonOriginalColumns.find(({ key }) => column.key === key);
          const originalWidth = originalColumn ? originalColumn.width.split('px')[0] : 0;

          return {
            ...column,
            width: `${originalWidth * amountOfComparisonsToDisplay.value}px`,
          };
        })
        .filter(
          (column) => hasOrderProcessPermission.value || column.key !== SUPPLIER_PRODUCTS_TABLE_HEADERS.PURCHASE_LIST
        );
    });

    const anyPeriodPreviousColumns = computed(() =>
      mutatedColumns.value.some(
        (column) => column.category === COLUMN_CATEGORIES.PREVIOUS_PERIOD_PRODUCTS_PURCHASED && column.hidden === false
      )
    );
    const anySimulationColumns = computed(() =>
      mutatedColumns.value.some((column) => column.category === COLUMN_CATEGORIES.SIMULATION && column.hidden === false)
    );

    const showPurchaseListFilter = computed(() => {
      const purchaseListColumn = mutatedColumns.value.find(
        (col) => col.key === SUPPLIER_PRODUCTS_TABLE_HEADERS.PURCHASE_LIST
      );
      return purchaseListColumn ? !purchaseListColumn.hidden : false;
    });

    const amountOfComparisonsToDisplay = computed(
      () =>
        props.dateRangesState.filter(
          (dateRange) => dateRange.startDate !== undefined && dateRange.endDate !== undefined
        ).length
    );

    const amountOfComparisonsInData = computed(() => {
      const amountInData = Object.keys(props.supplierProductsOverview).length - 1;
      if (amountOfComparisonsToDisplay.value < amountInData) {
        return amountOfComparisonsToDisplay.value;
      }

      return amountInData;
    });

    const exportExcel = () => {
      if (tableDataReadyForExcel.value) {
        const columnsForExcel = columns.value.filter((column) => !(column.hidden === true));
        const argsForExport = {
          productItems: tableDataReadyForExcel.value,
          tableColumns: columnsForExcel,
          dateRanges: dateRangesLabel.value,
          supplier: props.supplier,
          currencyFormat: currencyFormat.value,
        };

        exportSupplierProducts(argsForExport);
      }
    };

    const onTotalUpdate = (newDescriptionData) => (descriptionData.value = newDescriptionData);

    const onUpdateMutatedData = ({ data }) => (tableDataReadyForExcel.value = data);

    const onColumnsUpdate = (columnsToUpdate) => (columns.value = columnsToUpdate);

    const onPurchaseListFilterChange = (filter) => {
      purchaseListFilter.value = filter;
    };

    return {
      PRESET_TYPES,
      columns: mutatedColumns,
      dateRangesLabel,
      showPurchaseListFilter,
      descriptionData,
      anySimulationColumns,
      purchaseListFilter,
      anyPeriodPreviousColumns,
      amountOfComparisonsInData,
      onTotalUpdate,
      exportExcel,
      onUpdateMutatedData,
      formatCurrency: (number) => formatCurrencyOrDash(number, currencyFormat.value),
      onProductTextChange,
      onColumnsUpdate,
      onPurchaseListFilterChange,
      searchProductText,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

::v-deep {
  .el-input__prefix {
    width: 16px;
    [dir='ltr'] & {
      left: 5px;
    }
  }
  .el-picker-panel__shortcut {
    word-break: break-word;
  }
  .el-picker-panel__body {
    margin-inline-start: 190px !important;
  }
  .el-picker-panel__sidebar {
    width: 200px;
    padding: 16px;
  }
  .el-picker-panel__sidebar > .el-picker-panel__shortcut:first-child {
    color: #606266;
    font-weight: bold;
    margin-bottom: 4px;
    cursor: default;
  }
}
.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;

  .table-buttons {
    display: flex;
    gap: 0.5rem;
  }
}

.table-description {
  display: flex;
  gap: 2rem;
  margin-bottom: 0.5rem;
}

.total-divider {
  height: 0.75rem;
  border: 1px solid #d9dcde;
}

.total {
  justify-content: flex-end;
  padding-top: 8px;
  display: flex;
  gap: 1rem;
  align-items: center;
  .total-item {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    .total-text {
      color: #71767d;
    }
  }
  .total-with-icon {
    display: flex;
    gap: 0.25rem;
    align-items: center;
  }
}
</style>
