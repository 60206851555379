<template>
  <div class="d-flex gap-5">
    <SupplierDataCard class="col" :supplier-id="supplier.id" />
    <SupplierPurchasesChart class="col" :supplier-id="supplier.id" />
  </div>
</template>
<script>
import SupplierPurchasesChart from '../supplierPurchases/SupplierPurchasesChart';
import { SupplierDataCard } from '@/modules/order';

export default {
  components: {
    SupplierPurchasesChart,
    SupplierDataCard,
  },
  props: {
    supplier: { type: Object, required: true },
  },
};
</script>
